<template>
  <div></div>
</template>

<script>
import { getLoginByTempCode } from "@/api/setProcess.js";
import { getLogin } from "@/api/login";
import * as dd from "dingtalk-jsapi";
import { errorMsg } from "@/api/design";
export default {
  name: "Detail",
  data() {
    return {};
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    if (this.token) {
      this.ifHasToken();
    } else {
      this.notHasToken();
    }
  },

  methods: {
    async ifHasToken() {
      let data = await this.$store.dispatch("formData/getDetailData", {
        formId: this.$route.query.code,
        id: this.$route.query.objId,
        type: "see",
      });
      // 重置页面栈
      this.$store.commit("formData/setPageNameList", data);
    },
    notHasToken() {
      if (dd.env.platform !== "notInDingTalk") {
        dd.getAuthCode({
          corpId: "ding8ade6b5e21085cf135c2f4657eb6378f",
          success: async function (res) {
            let token = await this.$store.dispatch("user/isDDLogin", res.code);
            if (document.documentElement.clientWidth < 700) {
              this.toPath(token.data);
            }
            errorMsg(`'钉钉代办详情页', ${JSON.stringify(res)}`);
            // console.log(token)
            this.ifHasToken();
          },
          fail: function (err) {
            console.log(err, "err");
          },
        });
      } else {
        getLoginByTempCode(this.$route.query.tempCode).then((token) => {
          this.$store.commit("user/setToken", token.data);
          if (document.documentElement.clientWidth < 700) {
            // console.log(token.data);
            this.toPath(token.data);
          }
          getLogin().then(async (login) => {
            this.$store.commit("user/setUserInfo", login.data);
            this.ifHasToken();
          });
        });
      }
    },
    toPath(token) {
      if (process.env.NODE_ENV == "development") {
        window.location.href = `http://192.168.2.53:8080#/pages/list/listDetail?token=${token}&code=${this.$route.query.code}&objId=${this.$route.query.objId}`; // 开发环境
      } else if (
        process.env.NODE_ENV == "production" &&
        window.location.href.includes("http://test")
      ) {
        window.location.href = `http://test.cloud.yibeirui.com/h5/index.html#/pages/list/listDetail?token=${token}&code=${this.$route.query.code}&objId=${this.$route.query.objId}`; // 测试环境
      } else {
        window.location.href = `http://cloud.yibeirui.com/h5/index.html#/pages/list/listDetail?token=${token}&code=${this.$route.query.code}&objId=${this.$route.query.objId}`; // 生产环境
      }
    },
  },
};
</script>

<style>
</style>